@import 'src/utils/utils';

.title {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 18px;
  margin-right: 16px;
  margin-bottom: 20px;
  margin-left: 16px;
  color: $Main-1;
  gap: 15px;

  @media(min-width: $md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 45px;
  }

  @media(min-width: $lg) {
    margin-right: 0;
    margin-left: 0;
  }

  @media(min-width: $xxl) {
    @include box;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .button {
    min-height: unset;
    align-self: flex-start;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;

    @media(min-width: $md) {
      margin-top: 5px;
    }

    @media(min-width: $lg) {
      min-height: 44px;
      padding-top: 8px;
      padding-right: 20px;
      padding-bottom: 8px;
      padding-left: 20px;
    }

    svg {
      fill: none;
      stroke: $Corporate-2;
    }
  }

  &__main {
    @include Head-60-bold;
    margin: 0;
    word-break: break-word;
  }

  &__link {
    @include Text-16-reg;
    display: flex;
    align-items: center;
    color: $Main-1;
    text-decoration: none;
    transition: color 0.3s;
    gap: 10px;
    flex-shrink: 0;

    @media(min-width: $md) {
      margin-top: 11px;
    }

    @media(min-width: $lg) {
      margin-top: 10px;
    }

    &:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
      }
    }

    svg {
      stroke: currentcolor;
    }
  }
}